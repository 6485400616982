































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import useUser from '@/use/user'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    company: {
      type: String,
      required: false,
      default: null
    }
  },

  setup(props, { root, emit }) {
    const { getRoleName } = useUser({ root })

    const entries = ref<any[]>([])
    const isLoading = ref(false)
    const model = ref<any>(null)
    const search = ref(null)
    const count = ref(0)

    const fields = computed(() => {
      if (!model.value) return []

      return Object.keys(model.value as any).map(key => ({ key, value: (model.value as any)[key] || 'n/a' }))
    })
    const items = computed(() => entries.value)

    const fetch = (val: string | null) => {
      if (isLoading.value) return

      if (val) {
        isLoading.value = true

        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        axiosInstance
          .get('user', {
            params: {
              search: val || undefined,
              filter: props.company ? { company: props.company } : undefined
            }
          })
          .then(({ data }) => {
            const { users, total } = data
            count.value = total

            const ids = new Set(entries.value.map(e => e.id))
            entries.value = [...entries.value, ...users.filter((u: any) => !ids.has(u.id))]
          })
          .catch(error => console.log(error))
          .finally(() => isLoading.value = false)
      }
    }

    watch(() => search.value, fetch)
    watch(() => props.company, () => fetch(search.value))

    watch(() => model.value, val => emit('input', val))

    return { entries, isLoading, model, search, count, fields, items, getRoleName }
  }
})
